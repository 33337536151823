import * as Sentry from '@sentry/svelte'
import type { ClientInit, HandleClientError } from '@sveltejs/kit'
import { PUBLIC_ENVIRONMENT, PUBLIC_ADMIN_SENTRY_DSN } from '$env/static/public'
import { version } from '$app/environment'
import { utils } from '$lib/utils'

export const init: ClientInit = () => {
  Sentry.init({
    dsn: PUBLIC_ADMIN_SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration()],
    tracesSampleRate: 1.0,
    environment: PUBLIC_ENVIRONMENT,
    enabled: window.location.hostname !== 'localhost',
    release: version,
    ignoreErrors: utils.SENTRY_IGNORE_ERRORS,
  })

  Sentry.setTag('admin', 'browser')
}

export const handleError: HandleClientError = ({ error, event, status }) => {
  const exception = utils.formatError(error)

  if (status !== 404) {
    Sentry.captureException(exception, {
      contexts: { sveltekit: { event: JSON.stringify(event) } },
    })
  }

  return {
    user_message: 'Something went wrong',
    message: exception.message,
    url: event.url.href,
  }
}
